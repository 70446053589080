import React from "react";
import { Box } from "@mui/material";

import { ScrollProvider } from "../contexts/scroll";

import Container from "../components/container";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";
import { TermsCopy } from "../lib/terms";
import { SECTION_SX } from "../styles/shared";

export default function TermsPage() {
  return (
    <PageWrapper
      pageDescription="StatusVista terms and conditions"
      pageTitle="Terms and conditions"
    >
      <ScrollProvider>
        <Header variant="primary" />
        <Box component="section" sx={SECTION_SX}>
          <Container maxWidth="md">
            <TermsCopy />
          </Container>
        </Box>
        <Footer />
      </ScrollProvider>
    </PageWrapper>
  );
}
